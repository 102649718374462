import { Box } from '@mui/material'
import { deleteField, doc, getFirestore, onSnapshot, setDoc } from 'firebase/firestore'
import { useFirebaseAuth } from 'kitfit-community-common'
import { useCallback, useEffect, useState } from 'react'
import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import Colour from './Onboarding/Colour'
import Congratulations from './Onboarding/Congratulations'
import Hello from './Onboarding/Hello'
import Logo from './Onboarding/Logo'
import OnboardingContext, { OnboardingType } from './context/onboardingContext'

export const steps = ['hello', 'logo', 'colour', 'congratulations']

const Onboarding = () => {
	const navigate = useNavigate()
	const location = useLocation()
	useEffect(() => {
		if (location.pathname === '/onboarding') {
			navigate(`/onboarding/${steps[0]}`)
		}
	}, [location.pathname, navigate])

	const user = useFirebaseAuth()
	const [onboarding, setOnboarding] = useState<OnboardingType>()
	useEffect(() => {
		if (user.uid) {
			const db = getFirestore()
			return onSnapshot(
				doc(db, '/users', user.uid),
				(usersDocSnap) => { // onNext
					if (!usersDocSnap.exists() || !usersDocSnap.data().onboarding) {
						// TODO: don't create the user doc here, create it in a function triggered by auth.userCreate
						setDoc(usersDocSnap.ref, { uid: user.uid, onboarding: { step: steps[0], complete: false } }, { merge: true })
						return
					}
					const { onboarding } = usersDocSnap.data() as { onboarding?: OnboardingType }
					setOnboarding(onboarding)
				})
		}
	}, [user.uid])

	// complete the active step - pass in the step number that is being completed, and it's data.
	// will save data and navigate to the next step (or desired location, or home)
	const completeStep = useCallback(async (o: OnboardingType) => {
		if (user.uid) {
			const i = steps.indexOf(o.step) + 1
			const complete = i >= steps.length

			const db = getFirestore()
			const usersDocRef = doc(db, '/users', user.uid)
			await setDoc(usersDocRef, {
				onboarding: {
					...o,
					step: complete ? deleteField() : steps[i],
					complete
				}
			}, { merge: true })

			if (complete) {
				const desiredLocation = localStorage.getItem('desiredLocation')
				if (desiredLocation && !desiredLocation.includes('onboarding')) {
					navigate(desiredLocation)
				} else {
					navigate('/')
				}
			} else {
				navigate(`/onboarding/${steps[i]}`)
			}
		}
	}, [navigate, user.uid])
	const onboardingProvider = {
		onboarding,
		completeStep
	}

	if (!onboarding) {
		return null
	}

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
			<Box sx={{ maxWidth: '38rem', display: 'flex', justifyContent: 'center', flex: 1, borderRadius: 1 }}>
				<OnboardingContext.Provider value={onboardingProvider}>
					<Routes>
						<Route path="hello" element={<Hello />} />
						<Route path="logo" element={<Logo />} />
						<Route path="colour" element={<Colour />} />
						<Route path="congratulations" element={<Congratulations />} />
					</Routes>
					<Outlet />
				</OnboardingContext.Provider>
			</Box>
		</Box>
	)
}

export default Onboarding
