import { Box, SxProps } from '@mui/material'
import { InfoIcon } from 'kitfit-community-common'

interface InfoBoxProps {
	children: React.ReactNode,
	sx?: SxProps
}

const InfoBox: React.FC<InfoBoxProps> = ({ children, sx = {} }) => {
	return (
		<Box sx={{ display: 'flex', width: '100%', backgroundColor: 'white', p: 2, border: '1px solid #CCCCCC', borderRadius: 1, mb: 2, ...sx }}>
			<InfoIcon fill='#33CCFF' sx={{ mt: 0.5 }} />
			<Box sx={{ textAlign: 'left', ml: 2 }}>
				{children}
			</Box>
		</Box>
	)
}

export default InfoBox
