import { arrayRemove, arrayUnion, collection, deleteDoc, doc, getDocs, getFirestore, onSnapshot, query, serverTimestamp, setDoc, Timestamp, updateDoc, where } from 'firebase/firestore'
import stringify from 'json-stable-stringify'
import { BlockItem, initialiseBlocks, QuestionStatusEnum, useFirebaseAuth } from 'kitfit-community-common'
import CommunityContext, { CommunityInterface, CommunityType, QuestionType, useCommunityContextValue } from 'kitfit-community-common/context/CommunityContext'
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'

import ArticleStatusEnum from '../enums/ArticleStatusEnum'
import PriorityEnum from '../enums/PriorityEnum'
import RoleEnum from '../enums/RoleEnum'
import TicketStatusEnum from '../enums/TicketStatusEnum'

const defaultHostname = 'community.beanpunks.com' // TODO: this is for testing only (use real default host in future)
const defaultImageHostname = 'img.beanpunks.com'

export enum TicketsColumnsEnum {
	Name = 'Name',
	// Group = 'Group',
	// Assigned = 'Assigned',
	Priority = 'Priority',
	Status = 'Status'
}

export enum QuestionsColumnsEnum {
	Title = 'Name',
	Status = 'Status',
	Comments = 'Comments'
}

export enum ArticlesColumnsEnum {
	Title = 'Name',
	Status = 'Status',
	Comments = 'Comments'
}

export type UserProfileType = {
	name: string,
	uid: string,
	roles?: Array<RoleEnum>
}

type UserProfileIdentifierType = Pick<UserProfileType, 'uid'>

export const customerSegmentStageSchema = yup.object({
	name: yup.string().required().matches(/^[A-Za-z0-9 '\-]+$/, 'Stage name must only contain valid characters'), // eslint-disable-line no-useless-escape -- this is not a useless escape
	filter: yup.array(yup.string()).length(3).required()
}).required()
export type CustomerSegmentStageType = yup.InferType<typeof customerSegmentStageSchema> & {
	stageId: string,
	order: number
}
export type CustomerSegmentStageIdentifierType = Pick<CustomerSegmentStageType, 'stageId'>

export const customerSegmentSchema = yup.object({
	name: yup.string().required().matches(/^[A-Za-z0-9 '\-]+$/, 'Segment name must only contain valid characters'), // eslint-disable-line no-useless-escape -- this is not a useless escape
	description: yup.string().optional(),
	criteria: yup.array(yup.string()).length(3).required()
}).required()
export type CustomerSegmentType = yup.InferType<typeof customerSegmentSchema> & {
	segmentId: string,
	order: number,
	stages?: Array<CustomerSegmentStageType>
}
export type CustomerSegmentIdentifierType = Pick<CustomerSegmentType, 'segmentId'>
type CustomerSegmentPartialType = Partial<CustomerSegmentType>
export type CustomerSegmentUpdateType = CustomerSegmentPartialType & CustomerSegmentIdentifierType

type PlaybookType = {
	playbookId: string,
	name: string
}
export type PlaybookSummaryType = Pick<PlaybookType, 'playbookId' | 'name'>

type TicketType = {
	assignedAdmins?: Array<string>,
	assignedRoles?: Array<RoleEnum>,
	name: string,
	body: string,
	priority: PriorityEnum,
	status: TicketStatusEnum,
	ticketId: string,
	time: Timestamp,
	title: string,
	tag?: string,
	uid: string
}
type TicketIdentifierType = Pick<TicketType, 'ticketId'>
type TicketPartialType = Partial<TicketType>
type TicketUpdateType = TicketPartialType & TicketIdentifierType
type CanDeleteCustomerSegmentType = {
	canDelete: boolean,
	playbooks: Array<PlaybookSummaryType>
}
type CanDeleteCustomerSegmentStageType = {
	canDelete: boolean,
	playbooks: Array<PlaybookSummaryType>
}

type TicketsSummaryType = {
	total: number,
	unassigned: number,
	assignedToMe: number,
	myGroups: number
}

type CommentType = {
	body: string,
	commentId: string,
	time: Timestamp,
	uid: string
}
type CommentInsertType = Pick<CommentType, 'body'>

type ReplyType = {
	body: string,
	replyId: string,
	time: Timestamp,
	uid: string
}
type ReplyInsertType = Pick<ReplyType, 'body'>

type QuestionIdentifierType = Pick<QuestionType, 'questionId'>
type QuestionPartialType = Partial<QuestionType>
type QuestionUpdateType = QuestionPartialType & QuestionIdentifierType

type QuestionsSummaryType = {
	total: number,
	drafts: number,
	published: number
}

type ArticleType = {
	title: string,
	name: string,
	blocks: Array<BlockItem>,
	status: ArticleStatusEnum,
	countComments: number,
	articleId: string,
	time: Timestamp,
	uid: string
}
type ArticleIdentifierType = Pick<ArticleType, 'articleId'>
type ArticlePartialType = Partial<ArticleType>
type ArticleUpdateType = ArticlePartialType & ArticleIdentifierType

type ArticlesSummaryType = {
	total: number,
	drafts: number,
	published: number
}

interface AdminCommunityInterface extends CommunityInterface {
	admins?: Record<string, UserProfileType>,
	comments?: Record<string, CommentType>,
	ticketFilterPriority: PriorityEnum | '',
	ticketFilterRole: RoleEnum | '',
	ticketFilterStatus: Array<TicketStatusEnum>,
	replies?: Record<string, ReplyType>,
	selectTicket: (ticket: TicketIdentifierType) => void,
	setTicketFilterPriority: (priority: PriorityEnum | '') => void,
	setTicketFilterRole: (role: RoleEnum | '') => void,
	setTicketFilterStatus: (statuses: Array<TicketStatusEnum>) => void,
	setTicketSortBy: (ticketSortBy: TicketsColumnsEnum) => void,
	ticketSortBy: TicketsColumnsEnum,
	ticket?: TicketType,
	tickets?: Record<string, TicketType>,
	ticketsSummary?: TicketsSummaryType,
	updateTicket: (ticket: TicketUpdateType) => Promise<void>, // TODO: eventually pass back data?
	updateTicketAddAssignedAdmin: (ticket: TicketIdentifierType, user: UserProfileIdentifierType) => Promise<void>, // TODO: eventually pass back data?
	updateTicketAddComment: (ticket: TicketIdentifierType, comment: CommentInsertType) => Promise<void>, // TODO: eventually pass back data?
	updateTicketAddReply: (ticket: TicketIdentifierType, reply: ReplyInsertType) => Promise<void>, // TODO: eventually pass back data?
	updateTicketRemoveAssignedAdmin: (ticket: TicketIdentifierType, user: UserProfileIdentifierType) => Promise<void>, // TODO: eventually pass back data?
	userProfile?: UserProfileType,
	questions?: Record<string, QuestionType>,
	updateQuestion: (question: QuestionUpdateType) => Promise<void>, // TODO: eventually pass back data?
	setQuestionFilterStatus: (statuses: Array<QuestionStatusEnum>) => void,
	setQuestionSortBy: (questionSortBy: QuestionsColumnsEnum) => void,
	questionSortBy: QuestionsColumnsEnum,
	questionFilterStatus: Array<QuestionStatusEnum>,
	questionsSummary?: QuestionsSummaryType,
	article?: ArticleType,
	articles?: Record<string, ArticleType>,
	updateArticle: (article: ArticleUpdateType) => Promise<void>, // TODO: eventually pass back data?
	createArticle: () => Promise<string | undefined>,
	selectArticle: (article: ArticleIdentifierType) => void,
	setArticleFilterStatus: (statuses: Array<ArticleStatusEnum>) => void,
	setArticleSortBy: (articleSortBy: ArticlesColumnsEnum) => void,
	articleSortBy: ArticlesColumnsEnum,
	articleFilterStatus: Array<ArticleStatusEnum>,
	articlesSummary?: ArticlesSummaryType,
	customerSegments?: Record<string, CustomerSegmentType>,
	saveCustomerSegment: (segment: CustomerSegmentUpdateType) => Promise<void>,
	canDeleteCustomerSegment: (segment: CustomerSegmentIdentifierType) => Promise<CanDeleteCustomerSegmentType>,
	deleteCustomerSegment: (segment: CustomerSegmentIdentifierType) => Promise<void>,
	canDeleteCustomerSegmentStage: (segment: CustomerSegmentIdentifierType, stage: CustomerSegmentStageIdentifierType) => Promise<CanDeleteCustomerSegmentStageType>
}

const AdminCommunityContext = React.createContext<AdminCommunityInterface | undefined>(undefined)

const useAdminCommunityContextValue = () => {
	// note, we won't pass out setCommunity (etc) directly, instead wrapped functions that do the db storage, update the context, rollback on error, etc. - encapsulating the backend exposed by the context
	const [community, setCommunity] = useState<CommunityType>()
	const [adminsJson, setAdminsJson] = useState<string>()
	const [admins, setAdmins] = useState<Record<string, UserProfileType>>()
	const [tickets, setTickets] = useState<Record<string, TicketType>>()
	const [comments, setComments] = useState<Record<string, CommentType>>()
	const [replies, setReplies] = useState<Record<string, ReplyType>>()
	const [userProfile, setUserProfile] = useState<UserProfileType>()
	const [selectedTicketId, setSelectedTicketId] = useState<string>()
	const [ticket, setTicket] = useState<TicketType>()
	const [ticketSortBy, setTicketSortBy] = useState(TicketsColumnsEnum.Priority)
	const [ticketFilterPriority, setTicketFilterPriority] = useState<PriorityEnum | ''>('')
	const [ticketFilterRole, setTicketFilterRole] = useState<RoleEnum | ''>('')
	const [ticketFilterStatus, setTicketFilterStatus] = useState([TicketStatusEnum.INPROGRESS, TicketStatusEnum.ONHOLD])
	const [ticketsSummary, setTicketsSummary] = useState<TicketsSummaryType>()
	const [questions, setQuestions] = useState<Record<string, QuestionType>>()
	const [questionsSummary, setQuestionsSummary] = useState<QuestionsSummaryType>()
	const [question, setQuestion] = useState<QuestionType>()
	const [selectedQuestionId, setSelectedQuestionId] = useState<string>()
	const [questionSortBy, setQuestionSortBy] = useState(QuestionsColumnsEnum.Title)
	const [questionFilterStatus, setQuestionFilterStatus] = useState([QuestionStatusEnum.DRAFT])
	const [articles, setArticles] = useState<Record<string, ArticleType>>()
	const [articlesSummary, setArticlesSummary] = useState<ArticlesSummaryType>()
	const [article, setArticle] = useState<ArticleType>()
	const [selectedArticleId, setSelectedArticleId] = useState<string>()
	const [articleSortBy, setArticleSortBy] = useState(ArticlesColumnsEnum.Title)
	const [articleFilterStatus, setArticleFilterStatus] = useState([ArticleStatusEnum.DRAFT])
	const [availableImageIds, setAvailableImageIds] = useState<Array<string>>([])

	const user = useFirebaseAuth()
	const [communityId, setCommunityId] = useState<string>()
	// get the communities (just the first one for now) that this user is an admin of
	useEffect(() => {
		setCommunityId(undefined)
		if (user.uid) {
			const db = getFirestore()
			return onSnapshot(
				query(collection(db, '/communities'), where('admins', 'array-contains', user.uid)),
				(communitiesQuerySnap) => {
					console.log('AdminCommunityContext', `got ${communitiesQuerySnap.docChanges().length} communities for uid:${user.uid}`, communitiesQuerySnap.docChanges())
					if (communitiesQuerySnap.docs.length >= 1) {
						// TODO: choose site to administer if multiple
						console.log('AdminCommunityContext', `using ${communitiesQuerySnap.docs[0].id} community for uid:${user.uid}`)
						setCommunityId(communitiesQuerySnap.docs[0].id)
					} else {
						setCommunityId(undefined)
					}
				})
		}
	}, [user.uid])
	// get the community details (once we have a communityId)
	useEffect(() => {
		setCommunity(undefined)
		if (communityId) {
			const db = getFirestore()
			return onSnapshot(
				doc(db, '/communities', communityId),
				(communitiesDocSnap) => {
					const community = communitiesDocSnap.data() as CommunityType
					community.hostname = community.hostname || defaultHostname
					community.imageHostname = community.imageHostname || defaultImageHostname
					console.log('AdminCommunityContext', `got community data for communityId:${communityId}`, community)
					setCommunity(community)
					setAdminsJson(stringify(community.admins))
				})
		}
	}, [communityId])
	// get the admins userProfiles (once we have loaded the community, and when the admins change)
	useEffect(() => {
		setAdmins(undefined)
		const getAdminsUserProfiles = async (communityId: string, uids: Array<string>) => {
			const db = getFirestore()
			const adminsQuerySnap = await getDocs(query(collection(db, '/communities', communityId, 'userProfiles'), where('uid', 'in', uids)))
			const admins = adminsQuerySnap.docs.reduce((map, adminsDocSnap) => ({ ...map, [adminsDocSnap.id]: adminsDocSnap.data() }), {})
			console.log('AdminCommunityContext', `got ${adminsQuerySnap.docs.length} admins userProfiles for communityId:${communityId}`, admins)
			setAdmins(admins)
		}
		if (communityId && adminsJson) {
			const uids = JSON.parse(adminsJson)
			if (uids.length) {
				getAdminsUserProfiles(communityId, uids)
			}
		}
	}, [adminsJson, communityId])
	// get this user's userProfile for the community
	useEffect(() => {
		setUserProfile(undefined)
		if (communityId && user.uid) {
			const db = getFirestore()
			return onSnapshot(
				doc(db, '/communities', communityId, 'userProfiles', user.uid),
				(userProfileDocSnap) => {
					const userProfile = userProfileDocSnap.data() as UserProfileType
					console.log('AdminCommunityContext', `got userProfile data for uid:${user.uid}`, userProfile)
					setUserProfile(userProfile)
				})
		}
	}, [communityId, user.uid])
	// get the tickets (for this user) for the community (once we have loaded the community, and when the tickets change)
	useEffect(() => {
		setTicketsSummary(undefined)
		if (communityId && userProfile) {
			const db = getFirestore()
			const myRoles = userProfile.roles || []
			const relevantAssignments = [...myRoles, 'UNASSIGNED', user.uid]
			return onSnapshot(
				query(collection(db, '/communities', communityId, 'tickets'), where('assignedSummary', 'array-contains-any', relevantAssignments), where('active', '==', true)),
				(ticketsQuerySnap) => {
					// just a note here, the snapshot docs contain ALL of the matching records, however, what passed over the network (from firebase to us) is only the changes
					// if you want to process only changes you can look at docChanges rather than docs, but in this case (for now) we are okay just replacing the data (with what is essentially the same data, plus the changes)
					const tickets = ticketsQuerySnap.docs.reduce((map, ticketsDocSnap) => ({ ...map, [ticketsDocSnap.id]: ticketsDocSnap.data() as TicketType }), {} as Record<string, TicketType>)
					const ticketsUnassigned = Object.values(tickets).filter(ticket => !(ticket.assignedRoles?.length || ticket.assignedAdmins?.length))
					const ticketsAssignedToMe = Object.values(tickets).filter(ticket => user.uid && ticket.assignedAdmins?.includes(user.uid))
					const ticketsMyGroups = Object.values(tickets).filter(ticket => myRoles.some(role => ticket.assignedRoles?.includes(role)))
					const summary = { total: Object.keys(tickets).length, unassigned: ticketsUnassigned.length, assignedToMe: ticketsAssignedToMe.length, myGroups: ticketsMyGroups.length }
					console.log('AdminCommunityContext', `got ${ticketsQuerySnap.docChanges().length} tickets data for communityId:${communityId}`, ticketsQuerySnap.docChanges(), summary)
					setTicketsSummary(summary)
				})
		}
	}, [communityId, user.uid, userProfile])
	// get the tickets (with the applied filters)
	useEffect(() => {
		setTickets(undefined)
		if (communityId) {
			if (ticketFilterStatus.length === 0) {
				setTickets({})
				return
			}
			const db = getFirestore()
			let queryRef = query(collection(db, '/communities', communityId, 'tickets'), where('status', 'in', ticketFilterStatus))
			if (ticketFilterPriority) {
				queryRef = query(queryRef, where('priority', '==', ticketFilterPriority))
			}
			if (ticketFilterRole) {
				queryRef = query(queryRef, where('assignedRoles', 'array-contains', ticketFilterRole))
			}
			return onSnapshot(
				queryRef,
				(ticketsQuerySnap) => {
					const tickets = ticketsQuerySnap.docs.reduce((map, ticketsDocSnap) => ({ ...map, [ticketsDocSnap.id]: ticketsDocSnap.data() }), {})
					console.log('AdminCommunityContext', `filtered ${ticketsQuerySnap.docChanges().length} tickets for communityId:${communityId}`, { ticketFilterPriority, ticketFilterRole, ticketFilterStatus: ticketFilterStatus }, ticketsQuerySnap.docChanges())
					setTickets(tickets)
				})
		}
	}, [communityId, ticketFilterPriority, ticketFilterRole, ticketFilterStatus])
	// get the selected ticket (with extra data, and so it does not disappear if it drops out of the query)
	useEffect(() => {
		setTicket(undefined)
		setComments(undefined)
		setReplies(undefined)
		if (communityId && selectedTicketId) {
			const db = getFirestore()
			const unsubscribeTicket = onSnapshot(
				doc(db, '/communities', communityId, 'tickets', selectedTicketId),
				(ticketDocSnap) => {
					const ticket = ticketDocSnap.data() as TicketType
					console.log('AdminCommunityContext', `selected ticket ${selectedTicketId} for communityId:${communityId}`, ticket)
					setTicket(ticket)
				})
			const unsubscribeComments = onSnapshot(
				collection(db, '/communities', communityId, 'tickets', selectedTicketId, 'comments'),
				(commentsQuerySnap) => {
					const comments = commentsQuerySnap.docs.reduce((map, commentsDocSnap) => {
						const commentDoc = {
							...commentsDocSnap.data(),
							time: commentsDocSnap.data().time?.toDate ? commentsDocSnap.data().time : Timestamp.now() // patch the snapshot after add
						}
						return ({ ...map, [commentsDocSnap.id]: commentDoc })
					}, {})
					console.log('AdminCommunityContext', `got ${commentsQuerySnap.docChanges().length} comments for ticketId:${selectedTicketId}`, commentsQuerySnap.docChanges())
					setComments(comments)
				})
			const unsubscribeReplies = onSnapshot(
				collection(db, '/communities', communityId, 'tickets', selectedTicketId, 'replies'),
				(repliesQuerySnap) => {
					const replies = repliesQuerySnap.docs.reduce((map, repliesDocSnap) => {
						const replyDoc = {
							...repliesDocSnap.data(),
							time: repliesDocSnap.data().time?.toDate ? repliesDocSnap.data().time : Timestamp.now() // patch the snapshot after add
						}
						return ({ ...map, [repliesDocSnap.id]: replyDoc })
					}, {})
					console.log('AdminCommunityContext', `got ${repliesQuerySnap.docChanges().length} replies for ticketId:${selectedTicketId}`, repliesQuerySnap.docChanges())
					setReplies(replies)
				})
			return () => {
				unsubscribeTicket()
				unsubscribeComments()
				unsubscribeReplies()
			}
		}
	}, [communityId, selectedTicketId])

	const updateTicket = useCallback(async (ticket: TicketUpdateType) => {
		// TODO: local state changes first (which will pass out via context), then db update, with rollback if required, callers can wait on the promise if they need to
		if (communityId) {
			console.log('updateTicket', ticket)
			const db = getFirestore()
			await updateDoc(doc(db, '/communities', communityId, 'tickets', ticket.ticketId), ticket)
		}
	}, [communityId])
	const updateTicketAddAssignedAdmin = useCallback(async (ticket: TicketIdentifierType, user: UserProfileIdentifierType) => {
		if (communityId) {
			console.log('updateTicketAssignedAdmin', ticket, user)
			const db = getFirestore()
			await updateDoc(doc(db, '/communities', communityId, 'tickets', ticket.ticketId), { assignedAdmins: arrayUnion(user.uid) })
		}
	}, [communityId])
	const updateTicketRemoveAssignedAdmin = useCallback(async (ticket: TicketIdentifierType, user: UserProfileIdentifierType) => {
		if (communityId) {
			console.log('updateTicketAssignedAdmin', ticket, user)
			const db = getFirestore()
			await updateDoc(doc(db, '/communities', communityId, 'tickets', ticket.ticketId), { assignedAdmins: arrayRemove(user.uid) })
		}
	}, [communityId])
	const updateTicketAddComment = useCallback(async (ticket: TicketIdentifierType, comment: CommentInsertType) => {
		if (communityId) {
			console.log('updateTicketAddComment', ticket, comment)
			const db = getFirestore()
			const commentsDocRef = doc(collection(db, '/communities', communityId, 'tickets', ticket.ticketId, 'comments'))
			const commentDoc = {
				...comment,
				commentId: commentsDocRef.id,
				uid: user.uid,
				time: serverTimestamp()
			}
			await setDoc(commentsDocRef, commentDoc)
		}
	}, [communityId, user.uid])
	const updateTicketAddReply = useCallback(async (ticket: TicketIdentifierType, reply: ReplyInsertType) => {
		if (communityId) {
			console.log('updateTicketAddReply', ticket, reply)
			const db = getFirestore()
			const repliesDocRef = doc(collection(db, '/communities', communityId, 'tickets', ticket.ticketId, 'replies'))
			const replyDoc = {
				...reply,
				replyId: repliesDocRef.id,
				uid: user.uid,
				time: serverTimestamp()
			}
			await setDoc(repliesDocRef, replyDoc)
		}
	}, [communityId, user.uid])
	const selectTicket = useCallback((ticket: TicketIdentifierType) => {
		setSelectedTicketId(ticket.ticketId)
	}, [])

	// get the questions for the community (once we have loaded the community, and when the questions change)
	useEffect(() => {
		setQuestionsSummary(undefined)
		if (communityId && userProfile) {
			const db = getFirestore()
			return onSnapshot(
				// TODO: some filtering similar to tickets
				query(collection(db, '/communities', communityId, 'questions')),
				(questionsQuerySnap) => {
					const questions = questionsQuerySnap.docs.reduce((map, questionsDocSnap) => ({ ...map, [questionsDocSnap.id]: questionsDocSnap.data() as QuestionType }), {} as Record<string, QuestionType>)
					const questionsDrafts = Object.values(questions).filter(question => question.status === QuestionStatusEnum.DRAFT)
					const questionsPublished = Object.values(questions).filter(question => question.status === QuestionStatusEnum.PUBLISHED)
					const summary: QuestionsSummaryType = { total: Object.keys(questions).length, drafts: questionsDrafts.length, published: questionsPublished.length }
					console.log('AdminCommunityContext', `got ${questionsQuerySnap.docChanges().length} questions data for communityId:${communityId}`, questionsQuerySnap.docChanges(), summary)
					setQuestionsSummary(summary)
				})
		}
	}, [communityId, user.uid, userProfile])
	// get the questions (with the applied filters)
	useEffect(() => {
		setQuestions(undefined)
		if (communityId) {
			if (questionFilterStatus.length === 0) {
				setQuestions({})
				return
			}
			const db = getFirestore()
			const queryRef = query(collection(db, '/communities', communityId, 'questions'), where('status', 'in', questionFilterStatus))
			return onSnapshot(
				queryRef,
				(questionsQuerySnap) => {
					const questions = questionsQuerySnap.docs.reduce((map, questionsDocSnap) => ({ ...map, [questionsDocSnap.id]: questionsDocSnap.data() }), {})
					console.log('AdminCommunityContext', `filtered ${questionsQuerySnap.docChanges().length} questions for communityId:${communityId}`, { ticketFilterPriority, ticketFilterRole, ticketFilterStatus: questionFilterStatus }, questionsQuerySnap.docChanges())
					setQuestions(questions)
				})
		}
	}, [communityId, ticketFilterPriority, ticketFilterRole, questionFilterStatus])
	// get the selected question (with extra data, and so it does not disappear if it drops out of the query)
	useEffect(() => {
		setQuestion(undefined)
		setComments(undefined)
		setReplies(undefined)
		if (communityId && selectedQuestionId) {
			const db = getFirestore()
			const unsubscribeQuestion = onSnapshot(
				doc(db, '/communities', communityId, 'questions', selectedQuestionId),
				(questionDocSnap) => {
					const question = questionDocSnap.data() as QuestionType
					console.log('AdminCommunityContext', `selected question ${selectedQuestionId} for communityId:${communityId}`, question)
					setQuestion(question)
				})
			const unsubscribeComments = onSnapshot(
				collection(db, '/communities', communityId, 'questions', selectedQuestionId, 'comments'),
				(commentsQuerySnap) => {
					const comments = commentsQuerySnap.docs.reduce((map, commentsDocSnap) => {
						const commentDoc = {
							...commentsDocSnap.data(),
							time: commentsDocSnap.data().time?.toDate ? commentsDocSnap.data().time : Timestamp.now() // patch the snapshot after add
						}
						return ({ ...map, [commentsDocSnap.id]: commentDoc })
					}, {})
					console.log('AdminCommunityContext', `got ${commentsQuerySnap.docChanges().length} comments for questionId:${selectedQuestionId}`, commentsQuerySnap.docChanges())
					setComments(comments)
				})
			const unsubscribeReplies = onSnapshot(
				collection(db, '/communities', communityId, 'questions', selectedQuestionId, 'replies'),
				(repliesQuerySnap) => {
					const replies = repliesQuerySnap.docs.reduce((map, repliesDocSnap) => {
						const replyDoc = {
							...repliesDocSnap.data(),
							time: repliesDocSnap.data().time?.toDate ? repliesDocSnap.data().time : Timestamp.now() // patch the snapshot after add
						}
						return ({ ...map, [repliesDocSnap.id]: replyDoc })
					}, {})
					console.log('AdminCommunityContext', `got ${repliesQuerySnap.docChanges().length} replies for questionId:${selectedQuestionId}`, repliesQuerySnap.docChanges())
					setReplies(replies)
				})
			return () => {
				unsubscribeQuestion()
				unsubscribeComments()
				unsubscribeReplies()
			}
		}
	}, [communityId, selectedQuestionId])

	const updateQuestion = useCallback(async (question: QuestionUpdateType) => {
		if (communityId) {
			console.log('updateQuestion', question)
			const db = getFirestore()
			await updateDoc(doc(db, '/communities', communityId, 'questions', question.questionId), question)
		}
	}, [communityId])
	const selectQuestion = useCallback((question: QuestionIdentifierType) => {
		setSelectedQuestionId(question.questionId)
	}, [])

	// get the articles for the community (once we have loaded the community, and when the articles change)
	useEffect(() => {
		setArticlesSummary(undefined)
		if (communityId && userProfile) {
			const db = getFirestore()
			return onSnapshot(
				// TODO: some filtering similar to tickets
				query(collection(db, '/communities', communityId, 'articles')),
				(articlesQuerySnap) => {
					const articles = articlesQuerySnap.docs.reduce((map, articlesDocSnap) => ({ ...map, [articlesDocSnap.id]: articlesDocSnap.data() as ArticleType }), {} as Record<string, ArticleType>)
					const articlesDrafts = Object.values(articles).filter(article => article.status === ArticleStatusEnum.DRAFT)
					const articlesPublished = Object.values(articles).filter(article => article.status === ArticleStatusEnum.PUBLISHED)
					const summary: ArticlesSummaryType = { total: Object.keys(articles).length, drafts: articlesDrafts.length, published: articlesPublished.length }
					console.log('AdminCommunityContext', `got ${articlesQuerySnap.docChanges().length} articles data for communityId:${communityId}`, articlesQuerySnap.docChanges(), summary)
					setArticlesSummary(summary)
				})
		}
	}, [communityId, user.uid, userProfile])
	// get the articles (with the applied filters)
	useEffect(() => {
		setArticles(undefined)
		if (communityId) {
			if (articleFilterStatus.length === 0) {
				setArticles({})
				return
			}
			const db = getFirestore()
			const queryRef = query(collection(db, '/communities', communityId, 'articles'), where('status', 'in', articleFilterStatus))
			return onSnapshot(
				queryRef,
				(articlesQuerySnap) => {
					const articles = articlesQuerySnap.docs.reduce((map, articlesDocSnap) => {
						const articlesDoc = {
							...articlesDocSnap.data(),
							time: articlesDocSnap.data()?.time?.toDate ? articlesDocSnap.data().time : Timestamp.now() // patch the snapshot after add
						}
						return ({ ...map, [articlesDocSnap.id]: articlesDoc })
					}, {})
					console.log('AdminCommunityContext', `filtered ${articlesQuerySnap.docChanges().length} articles for communityId:${communityId}`, { ticketFilterPriority, ticketFilterRole, ticketFilterStatus: articleFilterStatus }, articlesQuerySnap.docChanges())
					setArticles(articles)
				})
		}
	}, [communityId, ticketFilterPriority, ticketFilterRole, articleFilterStatus])
	// get the selected article (with extra data, and so it does not disappear if it drops out of the query)
	useEffect(() => {
		setArticle(undefined)
		setComments(undefined)
		setReplies(undefined)
		if (communityId && selectedArticleId) {
			const db = getFirestore()
			const unsubscribeArticle = onSnapshot(
				doc(db, '/communities', communityId, 'articles', selectedArticleId),
				(articleDocSnap) => {
					const article = articleDocSnap.data() as ArticleType
					article.time = articleDocSnap.data()?.time?.toDate ? article.time : Timestamp.now() // patch the snapshot after add
					console.log('AdminCommunityContext', `selected article ${selectedArticleId} for communityId:${communityId}`, article)
					setArticle(article)
				})
			const unsubscribeComments = onSnapshot(
				collection(db, '/communities', communityId, 'articles', selectedArticleId, 'comments'),
				(commentsQuerySnap) => {
					const comments = commentsQuerySnap.docs.reduce((map, commentsDocSnap) => {
						const commentDoc = {
							...commentsDocSnap.data(),
							time: commentsDocSnap.data().time?.toDate ? commentsDocSnap.data().time : Timestamp.now() // patch the snapshot after add
						}
						return ({ ...map, [commentsDocSnap.id]: commentDoc })
					}, {})
					console.log('AdminCommunityContext', `got ${commentsQuerySnap.docChanges().length} comments for articleId:${selectedArticleId}`, commentsQuerySnap.docChanges())
					setComments(comments)
				})
			const unsubscribeReplies = onSnapshot(
				collection(db, '/communities', communityId, 'articles', selectedArticleId, 'replies'),
				(repliesQuerySnap) => {
					const replies = repliesQuerySnap.docs.reduce((map, repliesDocSnap) => {
						const replyDoc = {
							...repliesDocSnap.data(),
							time: repliesDocSnap.data().time?.toDate ? repliesDocSnap.data().time : Timestamp.now() // patch the snapshot after add
						}
						return ({ ...map, [repliesDocSnap.id]: replyDoc })
					}, {})
					console.log('AdminCommunityContext', `got ${repliesQuerySnap.docChanges().length} replies for articleId:${selectedArticleId}`, repliesQuerySnap.docChanges())
					setReplies(replies)
				})
			return () => {
				unsubscribeArticle()
				unsubscribeComments()
				unsubscribeReplies()
			}
		}
	}, [communityId, selectedArticleId])

	const updateArticle = useCallback(async (article: ArticleUpdateType) => {
		if (communityId) {
			console.log('updateArticle', article)
			const db = getFirestore()
			await updateDoc(doc(db, '/communities', communityId, 'articles', article.articleId), article)
		}
	}, [communityId])
	const selectArticle = useCallback((article: ArticleIdentifierType) => {
		setSelectedArticleId(article.articleId)
	}, [])
	const createArticle = useCallback(async () => {
		if (communityId) {
			const db = getFirestore()
			const docRef = doc(collection(db, '/communities', communityId, 'articles'))
			const article = {
				articleId: docRef.id,
				title: 'Untitled Article',
				blocks: initialiseBlocks(['header', 'paragraph']),
				status: ArticleStatusEnum.DRAFT,
				countComments: 0,
				time: serverTimestamp(),
				name: userProfile?.name,
				uid: userProfile?.uid
			}
			console.log('createArticle', article)
			await setDoc(docRef, article)
			return docRef.id
		}
	}, [communityId, userProfile?.name, userProfile?.uid])

	const setLogo = useCallback(async (logoStorageLocation: string, logoDownloadUrl: string) => {
		if (user.uid) {
			const db = getFirestore()
			const usersDocRef = doc(db, '/users', user.uid)
			console.log('setLogo', { logoStorageLocation, logoDownloadUrl })
			await setDoc(usersDocRef, {
				onboarding: { logoStorageLocation, logoDownloadUrl }
			}, { merge: true })
		}
	}, [user.uid])

	// get the images for the community (once we have loaded the community, and when the images change)
	useEffect(() => {
		setAvailableImageIds([])
		if (communityId) {
			const db = getFirestore()
			const imagesRef = query(collection(db, '/communities', communityId, 'images'), where('isLive', '==', true))
			return onSnapshot(
				imagesRef,
				(imagesQuerySnap) => {
					const imageIds = imagesQuerySnap.docs.map(imagesDocSnap => imagesDocSnap.id)
					console.log('AdminCommunityContext', `got ${imagesQuerySnap.docs.length} images for communityId:${communityId}`, imageIds)
					setAvailableImageIds(imageIds)
				}
			)
		}
	}, [communityId])
	const addImage = useCallback(async (imageStorageLocation: string, imageDownloadUrl: string) => {
		if (!communityId) {
			// TODO: do this pattern more (when this just can't happen)
			throw new Error('Missing communityId')
		}
		const db = getFirestore()
		const docRef = doc(collection(db, '/communities', communityId, 'images'))
		const imageDoc = {
			imageId: docRef.id,
			imageStorageLocation,
			imageDownloadUrl
		}
		console.log('addImage', imageDoc)
		await setDoc(docRef, imageDoc)
		return docRef.id
	}, [communityId])

	const [customerSegments, setCustomerSegments] = useState<Record<string, CustomerSegmentType>>({})
	useEffect(() => {
		setCustomerSegments({})
		if (communityId) {
			const db = getFirestore()
			const customerSegments = query(collection(db, '/communities', communityId, 'customerSegments'))
			return onSnapshot(
				customerSegments,
				(customerSegmentsQuerySnap) => {
					const customerSegments = customerSegmentsQuerySnap.docs.reduce((map, customerSegmentsDocSnap) => ({ ...map, [customerSegmentsDocSnap.id]: customerSegmentsDocSnap.data() }), {})
					console.log('AdminCommunityContext', `got ${customerSegmentsQuerySnap.docChanges().length} customerSegments for communityId:${communityId}`, customerSegmentsQuerySnap.docChanges())
					setCustomerSegments(customerSegments)
				}
			)
		}
	}, [communityId])
	const saveCustomerSegment = useCallback(async (segment: CustomerSegmentUpdateType) => {
		if (!communityId) {
			throw new Error('Missing communityId')
		}
		const db = getFirestore()
		const customerSegmentDocRef = segment.segmentId ? doc(db, '/communities', communityId, 'customerSegments', segment.segmentId) : doc(collection(db, '/communities', communityId, 'customerSegments'))
		segment.segmentId = customerSegmentDocRef.id
		console.log('saveCustomerSegment', segment)
		await setDoc(customerSegmentDocRef, segment, { merge: true })
	}, [communityId])
	const canDeleteCustomerSegment = useCallback(async (segment: CustomerSegmentIdentifierType) => {
		if (!communityId) {
			throw new Error('Missing communityId')
		}
		const db = getFirestore()
		const playbooksQueryRef = query(collection(db, '/communities', communityId, 'playbooks'), where('segmentId', '==', segment.segmentId))
		const playbooksQuerySnaps = await getDocs(playbooksQueryRef)
		const canDelete = playbooksQuerySnaps.empty
		const playbooks = playbooksQuerySnaps.docs.map((playbooksDocSnap) => {
			const { playbookId, name } = playbooksDocSnap.data()
			return { playbookId, name }
		})
		console.log('canDeleteCustomerSegment', segment, {
			canDelete,
			playbooks
		})
		return {
			canDelete,
			playbooks
		}
	}, [communityId])
	const canDeleteCustomerSegmentStage = useCallback(async (segment: CustomerSegmentIdentifierType, stage: CustomerSegmentStageIdentifierType) => {
		if (!communityId) {
			throw new Error('Missing communityId')
		}
		const db = getFirestore()
		const playbooksQueryRef = query(collection(db, '/communities', communityId, 'playbooks'), where('segmentId', '==', segment.segmentId), where('stageId', '==', stage.stageId))
		const playbooksQuerySnaps = await getDocs(playbooksQueryRef)
		const canDelete = playbooksQuerySnaps.empty
		const playbooks = playbooksQuerySnaps.docs.map((playbooksDocSnap) => {
			const { playbookId, name } = playbooksDocSnap.data()
			return { playbookId, name }
		})
		console.log('canDeleteCustomerSegmentStage', segment, {
			canDelete,
			playbooks
		})
		return {
			canDelete,
			playbooks
		}
	}, [communityId])
	const deleteCustomerSegment = useCallback(async (segment: CustomerSegmentIdentifierType) => {
		if (!communityId) {
			throw new Error('Missing communityId')
		}
		const db = getFirestore()
		const customerSegmentDocRef = doc(db, '/communities', communityId, 'customerSegments', segment.segmentId)
		console.log('deleteCustomerSegment', segment)
		await deleteDoc(customerSegmentDocRef)
	}, [communityId])
	const value = useMemo(() => ({
		admins,
		community,
		ticket,
		tickets,
		ticketsSummary,
		comments,
		replies,
		userProfile,
		updateTicket,
		updateTicketAddAssignedAdmin,
		updateTicketRemoveAssignedAdmin,
		updateTicketAddComment,
		updateTicketAddReply,
		selectTicket,
		ticketSortBy,
		setTicketSortBy,
		ticketFilterPriority,
		setTicketFilterPriority,
		ticketFilterRole,
		setTicketFilterRole,
		ticketFilterStatus,
		setTicketFilterStatus,
		questions,
		updateQuestion,
		questionsSummary,
		question,
		questionFilterStatus,
		setQuestionFilterStatus,
		questionSortBy,
		setQuestionSortBy,
		selectQuestion,
		articles,
		updateArticle,
		createArticle,
		articlesSummary,
		article,
		articleFilterStatus,
		setArticleFilterStatus,
		articleSortBy,
		setArticleSortBy,
		selectArticle,
		setLogo,
		addImage,
		availableImageIds,
		customerSegments,
		saveCustomerSegment,
		canDeleteCustomerSegment,
		deleteCustomerSegment,
		canDeleteCustomerSegmentStage
	}), [addImage, admins, article, articleFilterStatus, articleSortBy, articles, articlesSummary, availableImageIds, canDeleteCustomerSegment, canDeleteCustomerSegmentStage, comments, community, createArticle, customerSegments, deleteCustomerSegment, question, questionFilterStatus, questionSortBy, questions, questionsSummary, replies, saveCustomerSegment, selectArticle, selectQuestion, selectTicket, setLogo, ticket, ticketFilterPriority, ticketFilterRole, ticketFilterStatus, ticketSortBy, tickets, ticketsSummary, updateArticle, updateQuestion, updateTicket, updateTicketAddAssignedAdmin, updateTicketAddComment, updateTicketAddReply, updateTicketRemoveAssignedAdmin, userProfile])
	return value
}

export const AdminCommunityContextProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
	const adminCommunityContext = useAdminCommunityContextValue()
	const baseCommunityContextValue = useCommunityContextValue(adminCommunityContext)

	const communityContextValue = { ...baseCommunityContextValue, ...adminCommunityContext }

	return (
		<AdminCommunityContext.Provider value={communityContextValue}>
			<CommunityContext.Provider value={communityContextValue}>
				{children}
			</CommunityContext.Provider>
		</AdminCommunityContext.Provider>
	)
}

export const useAdminCommunityContext = () => {
	const adminCommunityContext = useContext(AdminCommunityContext)

	if (adminCommunityContext === undefined) {
		throw new Error('AdminCommunityContext was used outside of a provider')
	}

	return adminCommunityContext
}

export default AdminCommunityContext
