import { useRef, useContext, useEffect, useState } from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Button, Select, MenuItem, FormControl } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-translatify-next'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { ArrowCircleUp, ArrowCircleDown, FilterAlt } from '@mui/icons-material'
import { compareQuestionStatus, QuestionStatusEnum } from 'kitfit-community-common'

import HeaderContext from './context/headerContext'
import { QuestionsColumnsEnum, useAdminCommunityContext } from './context/AdminCommunityContext'
import QuestionStatusSelector from './components/QuestionStatusSelector'

dayjs.extend(relativeTime)

const Questions = () => {
	const headerContext = useRef(useContext(HeaderContext))
	const { translate } = useTranslation()

	const { questions, updateQuestion, questionSortBy, setQuestionSortBy, questionFilterStatus, setQuestionFilterStatus } = useAdminCommunityContext()
	const [showFilters, setShowFilters] = useState(false)

	useEffect(() => {
		headerContext.current.setHeader(
			<Box sx={{ flex: '1' }}>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<Box sx={{ mr: 2 }}>
							{translate('Questions')}
						</Box>
					</Box>
					<Box sx={{ flex: '1', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
						<Box>
							<Button sx={{ mr: 1 }} variant='contained' startIcon={<FilterAlt />} endIcon={showFilters ? <ArrowCircleUp /> : <ArrowCircleDown />} onClick={() => setShowFilters(showFilters => !showFilters)}>Filters</Button>
						</Box>
						<FormControl>
							<Select
								size='small'
								value={questionSortBy}
								onChange={e => setQuestionSortBy(e.target.value as QuestionsColumnsEnum)}
								inputProps={{ sx: { display: 'flex', background: '#fff' } }}
							>
								<MenuItem value={QuestionsColumnsEnum.Title}>Sort by&nbsp;<strong>Title</strong></MenuItem>
								<MenuItem value={QuestionsColumnsEnum.Status}>Sort by&nbsp;<strong>Status</strong></MenuItem>
								<MenuItem value={QuestionsColumnsEnum.Comments}>Sort by&nbsp;<strong>Comments</strong></MenuItem>
							</Select>
						</FormControl>
						<Box sx={{ ml: 1 }}><Button variant='contained'>New Question</Button></Box>
					</Box>
				</Box>
				{showFilters && <Box component={Paper} sx={{ display: 'flex', mt: 1, p: 2 }}>
					<Box>
						<Box sx={{ fontSize: '0.8rem' }}>Statuses</Box>
						<QuestionStatusSelector sx={{ mr: 2 }} multiple value={questionFilterStatus} setValue={(status: any) => setQuestionFilterStatus(status as Array<QuestionStatusEnum>)} />
					</Box>
				</Box>}
			</Box>
		)
	}, [questionFilterStatus, setQuestionFilterStatus, setQuestionSortBy, showFilters, questionSortBy, translate])

	return (
		<Box sx={{ display: 'block', p: 0, width: '100%' }}>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableHead>
						<TableRow sx={{ backgroundColor: '#fafafa' }}>
							<TableCell sx={{ fontWeight: 'bold' }}>{translate('Title')}</TableCell>
							<TableCell sx={{ fontWeight: 'bold' }}>{translate('Status')}</TableCell>
							<TableCell sx={{ fontWeight: 'bold' }}>{translate('Comments')}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{questions ? (
							Object.values(questions)
								.sort((a, b) => {
									switch (questionSortBy) {
										case QuestionsColumnsEnum.Comments:
											return a.countComments - b.countComments
										case QuestionsColumnsEnum.Status:
											return compareQuestionStatus(a.status, b.status)
										case QuestionsColumnsEnum.Title:
										default:
											return a.title.localeCompare(b.title)
									}
								})
								.map(question => (
									<TableRow
										key={question.questionId}
										sx={{ '&:last-child td, &:last-child th': { border: 0 }, 'cursor': 'pointer', ':hover': { backgroundColor: '#f3f3f3' } }}
									>
										<TableCell component="th" scope="row">
											<Box component={Link} to={`/question/${question.questionId}`} sx={{ color: 'black', textDecoration: 'none' }}>
												<Box sx={{ fontWeight: 'bold' }}>{question.title}</Box>
												<Box sx={{ color: 'gray', fontSize: '0.8rem' }}>By <Box component='span' sx={{ fontWeight: 'bold' }}>{question.name}</Box> - {`${dayjs(question.time.toDate()).fromNow()}`}</Box>
											</Box>
										</TableCell>
										<TableCell><QuestionStatusSelector value={question.status} setValue={(status: any) => updateQuestion({ questionId: question.questionId, status })} /></TableCell>
										<TableCell component="th" scope="row">
											<Box>{question.countComments}</Box>
										</TableCell>
									</TableRow>
								))
						) : (
							<TableRow>
								<TableCell colSpan={5}>
									<CircularProgress />
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}

export default Questions
