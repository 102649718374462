import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import HomeIcon from '@mui/icons-material/Home'
import { Link, To } from 'react-router-dom'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { IconType } from 'kitfit-community-common'
import { SvgIconTypeMap } from '@mui/material'

//	Returns what colour coding we want based on value - TODO: configurable per user and category in their profile, eg: 5 tickets might not be many for one user, but a lot for another user, so the colours should reflect that.
const getNumberColour = (number?: number) => {
	if (number === undefined) return 'black'
	if (number < 4) {
		return 'green'
	}
	if (number < 8) {
		return 'blue'
	}
	return number > 0 ? 'red' : 'black'
}

export interface ItemType {
	href: To,
	number?: number,
	isSelected: boolean,
	label: string
}
export interface SidebarButtonPropsType {
	Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> | IconType,
	isSelected?: boolean,
	label: string,
	number?: number,
	href: To
}

const SidebarButton: React.FC<SidebarButtonPropsType> = (props) => {
	const { Icon = HomeIcon, isSelected, label, number, href } = props
	const colour = getNumberColour(number)

	return (
		<Box sx={{
			display: 'flex',
			justifyContent: 'center',
			...(isSelected ? {
				backgroundColor: 'primary.contrastText',
				border: '1px solid transparent',
				transition: 'all 0.2s'
			} : {
				backgroundColor: 'inherit',
				border: '1px solid transparent',
				transition: 'all 0.2s'
			}),
			...(isSelected ? {
				backgroundColor: 'inherit',
				border: '1px solid transparent'
			} : []),
			height: '72px'
		}} title={`${label}${number !== undefined ? ' (' + (number || 0) + ' items)' : ''}`}>
			<Button component={Link} to={href} sx={{
				'textTransform': 'none',
				'margin': '0',
				'marginTop': '0.66rem',
				'marginBottom': '0.66rem',
				'color': 'text.primary',
				'border': '1px solid transparent',
				'display': 'flex',
				'backgroundColor': 'inherit',
				'transition': 'all 0.2s',
				':hover': {
					cursor: 'pointer',
					backgroundColor: '#44444f',
					border: '1px solid inherit',
					fontWeight: 'bold'
				},
				...(isSelected ? {
					'fontWeight': 'bold',
					'backgroundColor': '#ffffff',
					':hover': {
						cursor: 'pointer',
						backgroundColor: '#ffffff',
						border: '1px solid transparent',
						fontWeight: 'bold'
					}
				} : []),
				...({
					width: '3rem',
					minWidth: '3rem',
					justifyContent: 'center',
					pl: 0,
					pr: 0
				})
			}}>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '2rem' }}>
					<Icon {...(!isSelected ? { fill: '#ffffff' } : { fill: '#02a2a2f' })} />
				</Box>
				{number !== undefined && <Box sx={{
					display: 'flex',
					position: 'absolute',
					top: '0.2rem',
					right: '0.1rem',
					height: '0.5rem',
					width: '0.5rem',
					borderRadius: '100%',
					backgroundColor: colour
				}}></Box>}
			</Button>
		</Box>
	)
}

export default SidebarButton
