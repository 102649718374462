import { useRef, useContext, useEffect } from 'react'
import { Box, Button } from '@mui/material'
import { UsersIcon } from 'kitfit-community-common'
import { useNavigate } from 'react-router'

import HeaderContext from './context/headerContext'

const Settings = () => {
	const headerContext = useRef(useContext(HeaderContext))
	useEffect(() => {
		headerContext.current.setHeader('Settings')
	}, [])
	const navigate = useNavigate()

	return (
		<Box sx={{ display: 'block', p: 0, width: '100%' }}>
			<Button variant='outlined' sx={{ backgroundColor: 'white', width: '12rem', display: 'flex' }} onClick={() => navigate('/settings/segments')}>
				<UsersIcon sx={{ mt: '0.25rem' }} />
				<Box sx={{ textAlign: 'left', ml: 2 }}>Customer segments configuration</Box>
			</Button>
		</Box>
	)
}

export default Settings
