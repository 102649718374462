import { useState, useEffect, PropsWithChildren, useRef, useContext, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import { useTheme } from '@mui/material/styles'
// import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
//	Ref: https://mui.com/material-ui/customization/breakpoints/
import useMediaQuery from '@mui/material/useMediaQuery'
import { useFirebaseAuth, HomeIcon, TasksIcon, UsersIcon, SearchIcon, PlaybookIcon, CogIcon } from 'kitfit-community-common'

// import SidebarButton from '../components/SidebarButton'
import SidebarMenu, { MenuStructureType } from '../components/SidebarMenu'
import Copyright from '../components/Copyright'
import ThemeContext from '../context/themeContext'
import HeaderContext from '../context/headerContext'
import { useAdminCommunityContext } from '../context/AdminCommunityContext'

const Layout: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
	const user = useFirebaseAuth()
	const [header, setHeader] = useState<ReactNode>()
	const [showMenu, setShowMenu] = useState<boolean>(false)
	const [pathname, setPathname] = useState<string>()
	const [locationSearch, setLocationSearch] = useState<string>()

	const theme = useTheme()
	const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'))

	const location = useLocation()
	useEffect(() => {
		if (pathname !== location.pathname) {
			setPathname(location.pathname)
		}
		if (locationSearch !== location.search) {
			setLocationSearch(location.search)
		}
	}, [location, locationSearch, pathname])

	const themeContext = useRef(useContext(ThemeContext))
	const light = themeContext.current.theme

	const { community, ticketsSummary, questionsSummary, articlesSummary } = useAdminCommunityContext()

	//	TODO: The menu structure will be dynamic for different user roles, so we define it here for now so - we need to move this somewhere (maybe in the SidebarMenu component), and be able to provide overrides, eg: a user role might not need access to Questions, etc...
	const menuStructure: MenuStructureType = {
		top: [
			{ Icon: HomeIcon, label: 'Home', href: '/home', number: 3 },
			{
				Icon: TasksIcon,
				label: 'Tickets',
				href: '/tickets',
				number: ticketsSummary?.total,
				isSelected: pathname?.indexOf('/ticket') === 0
			},
			{
				Icon: UsersIcon,
				label: 'Questions',
				href: '/questions',
				number: questionsSummary?.total,
				isSelected: pathname?.indexOf('/question') === 0
			},
			{
				Icon: PlaybookIcon,
				label: 'Articles',
				href: '/articles',
				number: articlesSummary?.total,
				isSelected: pathname?.indexOf('/article') === 0
			},
			{ Icon: CogIcon, label: 'Settings', href: '/settings', isSelected: pathname?.indexOf('/settings') === 0 }
		],
		bottom: [
			{ Icon: SearchIcon, label: 'Search', href: '/search' }
		]
	}

	const handleShowMenu = () => {
		setShowMenu(true)
	}

	const handleHideMenu = () => {
		setShowMenu(false)
	}

	const glass = {
		backgroundColor: 'rgba(255, 255, 255, .66)',
		backdropFilter: 'blur(10px)'
	}

	const glassSideBar = {
		backgroundColor: 'rgba(42, 42, 47, .90)',
		backdropFilter: 'blur(10px)'
	}

	const glassBright = {
		// backgroundColor: 'rgba(0, 0, 0, .25)',	//	<- Woah! Dark glass.
		backgroundColor: 'rgba(255, 255, 255, .75)',
		backdropFilter: 'blur(10px)'
	}

	if (!community) {
		return null
	}

	return (
		<HeaderContext.Provider value={{ header, setHeader }}>
			<Box sx={{ minHeight: 'calc(100vh - 10rem)', display: 'flex', gap: '1px', boxShadow: '0px 10px 10px rgb(0 0 0 / 10%)', m: 2, borderBottomLeftRadius: '0.5rem', borderTopLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }}>
				{!isSmallBreakpoint && (<Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', ...({ width: '4.5rem' }) }}>
					<Box sx={{ height: '6rem', ...glassSideBar, display: 'flex', p: 0, justifyContent: 'center', alignItems: 'center', borderTopLeftRadius: '0.5rem' }}>
						<Box sx={{ height: '52px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
							<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<img alt="Logo" src='/logo.svg' style={{
									height: '3rem',
									marginRight: 'inherit'
								}} />
							</Box>
						</Box>
					</Box>

					<Box sx={{ ...glassSideBar, display: 'flex', flex: 1, zIndex: 1, ...({ width: '4.5rem' }), position: 'relative', flexDirection: 'column', transition: 'all 0.2s', borderBottomLeftRadius: '0.5rem' }}>
						<SidebarMenu menuStructure={menuStructure} pathname={pathname} />
					</Box>
				</Box>)}

				<Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', gap: '1px', width: '100%' }}>
					<Box sx={{ display: 'flex', p: 2, ...glassBright, minHeight: '4.5rem', alignItems: 'center', borderTopRightRadius: '0.5rem' }}>
						{isSmallBreakpoint && (<>
							<Box sx={{ position: 'absolute', right: '1rem', top: '1rem' }}>
								{!showMenu && <MenuIcon onClick={handleShowMenu} />}
								{showMenu && <CloseIcon onClick={handleHideMenu} />}
							</Box>
							{showMenu && (
								<Box sx={{ position: 'absolute', zIndex: 2, top: '3rem', right: 0, ...glass, boxShadow: '0px 10px 10px rgb(0 0 0 / 10%)', borderRadius: 1 }}>
									<SidebarMenu menuStructure={menuStructure} pathname={pathname} />
								</Box>
							)}
						</>)}
						{header}
					</Box>
					<Box sx={{ display: 'flex', p: 2, flex: 1, ...glass, borderBottomRightRadius: '0.5rem' }}>
						<Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }} >
							{children}
						</Box>
					</Box>
				</Box>
			</Box>
			<Copyright sx={{ mt: 8, mb: 4 }} />
		</HeaderContext.Provider>
	)
}

export default Layout
