import { FirebaseApp, getApp, initializeApp } from 'firebase/app'
import { useEffect, useMemo, useState } from 'react'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getStorage, connectStorageEmulator } from 'firebase/storage'

import firebaseConfig from '../config/firebase'

const useFirebase = () => {
	const [app, setApp] = useState<FirebaseApp>()

	useEffect(() => {
		const initialise = async () => {
			let firebaseApp
			try {
				firebaseApp = getApp()
			} catch {
				if (process.env.NODE_ENV === 'development') {
					firebaseApp = initializeApp(firebaseConfig)
					console.log('firebase projectId', firebaseConfig.projectId)
				} else {
					const config = await (await fetch('/__/firebase/init.json')).json()
					firebaseApp = initializeApp(config)
				}
				// env var is set by npm start to run in emulators mode
				// checking location.origin is a pretty bad way to detect, but there is nothing else I can find that can detect hosting running in the emulator
				if (process.env.REACT_APP_USE_EMULATORS || global.location.origin === 'http://localhost:5000') {
					const auth = getAuth()
					connectAuthEmulator(auth, 'http://localhost:9099')
					const functions = getFunctions(undefined, 'australia-southeast1')
					connectFunctionsEmulator(functions, 'localhost', 5001)
					const firestore = getFirestore()
					connectFirestoreEmulator(firestore, 'localhost', 8080)
					const storage = getStorage()
					connectStorageEmulator(storage, 'localhost', 9199)
					console.log('all emulators connected')
				}
				// TODO: we should make all these separate, let the npm script decide which emulators to start and hook up
				if (process.env.REACT_APP_USE_FUNCTION_EMULATORS || global.location.origin === 'http://localhost:5000') {
					const functions = getFunctions(undefined, 'australia-southeast1')
					connectFunctionsEmulator(functions, 'localhost', 5001)
					console.log('function emulator connected')
				}
			}

			setApp(firebaseApp)
		}
		initialise()
	}, [])

	return useMemo(() => [app], [app])
}

export default useFirebase
