import Box from '@mui/material/Box'

import SidebarButton, { SidebarButtonPropsType } from './SidebarButton'

export interface MenuStructureType {
	top: Array<SidebarButtonPropsType>,
	bottom: Array<SidebarButtonPropsType>
}

export interface SidebarMenuProps {
	pathname?: string,
	menuStructure: MenuStructureType
}

const SidebarMenu = (props: SidebarMenuProps) => {
	const { pathname, menuStructure } = props

	return (
		<Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', flexDirection: 'column', gap: 0.33 }}>
			<Box>
				{menuStructure?.top && menuStructure.top.map((menu: SidebarButtonPropsType) => (
					<SidebarButton
						key={menu.href + menu.label}
						Icon={menu.Icon}
						href={menu.href}
						label={menu.label}
						number={menu.number}
						isSelected={menu.isSelected !== undefined ? menu.isSelected : pathname === menu.href}
					/>
				))}
			</Box>
			<Box sx={{ mb: 0.9 }}>
				{menuStructure?.bottom && menuStructure.bottom.map((menu: SidebarButtonPropsType) => (
					<SidebarButton
						key={menu.href + menu.label}
						Icon={menu.Icon}
						href={menu.href}
						label={menu.label}
						number={menu.number}
						isSelected={menu.isSelected !== undefined ? menu.isSelected : pathname === menu.href}
					/>
				))}
			</Box>
		</Box >
	)
}

export default SidebarMenu
